import GATSBY_COMPILED_MDX from "/opt/build/repo/src/content/notes/windows/window-os.mdx";
import {Link as GatsbyLink, graphql, useStaticQuery} from "gatsby";
import React, {useState, useEffect, memo} from "react";
import {Grid, GridItem, Box, Container, Heading, Flex, Text, Tag} from "@chakra-ui/react";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import pathToJsonTree from "utils/path-to-json-tree";
import {generate} from "shortid";
import {BreadCrumbGroup, HalfByHalfSection, LinkOne, MdxTOC} from "components";
import SubjectTree from "components/modules/subject-tree";
import MDXLayout from "components/base/layout/mdx-layout";
import SideNavContainer from "components/modules/side-nav-container";
import {atom, useAtom} from 'jotai';
import {treeAtom} from "store";
function NoteTemplate({children, path, pageContext, location}) {
  const {next, previous, node, title} = pageContext;
  const {tableOfContents, fields} = node;
  const {recentNotes} = useStaticQuery(query);
  const [notes, setNotes] = useState(recentNotes.edges);
  const [tree, setTree] = useState();
  const [treeState, setTreeState] = useAtom(treeAtom);
  const [currentEl, setCurrentEl] = useState([]);
  useEffect(() => {}, [treeState, setTreeState]);
  const makeDirTree = edges => {
    let jsonTree = [];
    for (const e of edges) {
      const path = e.node.parent.relativePath;
      if (path.match(/trash/gi) < 1) {
        const link = e.node.fields.path;
        const i = path.replace("notes/", "").split("/");
        jsonTree = pathToJsonTree(i, link, jsonTree);
      }
    }
    return jsonTree;
  };
  useEffect(() => {
    if (recentNotes.edges.length > 0) {
      const newTree = makeDirTree(recentNotes.edges);
      setTree(newTree);
    }
  }, []);
  const NextArticle = () => React.createElement(Box, {
    key: generate()
  }, React.createElement(Box, {
    to: `/${next.fields.path}`,
    as: GatsbyLink
  }, React.createElement(Box, {
    p: {
      base: 5,
      md: 10
    }
  }, React.createElement(Heading, {
    as: "h4",
    size: "lg"
  }, next.frontmatter.title))));
  const PrevArticle = () => React.createElement(Box, {
    key: generate()
  }, React.createElement(Box, {
    as: GatsbyLink,
    to: `/${previous.fields.path}`
  }, React.createElement(Box, {
    p: {
      base: 5,
      md: 10
    }
  }, React.createElement(Heading, {
    as: "h4",
    size: "lg"
  }, previous.frontmatter.title))));
  return React.createElement(React.Fragment, null, React.createElement(Box, {
    minHeight: "100vh"
  }, React.createElement(Container, {
    maxW: "container.xxl"
  }, React.createElement(Grid, {
    templateColumns: "repeat(12, 1fr)",
    gap: {
      base: 0,
      lg: 6
    }
  }, React.createElement(SideNavContainer, {
    display: {
      base: "none",
      lg: "block"
    },
    as: GridItem,
    colSpan: {
      base: 0,
      lg: 3
    },
    pl: {
      base: 0,
      lg: 4
    },
    py: 10
  }, React.createElement(SubjectTree, {
    tree: tree
  })), React.createElement(Box, {
    mx: "auto",
    px: {
      base: 0,
      md: 4,
      lg: 4
    },
    pt: 20,
    pb: 20,
    as: GridItem,
    colSpan: {
      base: 12,
      lg: 6
    }
  }, React.createElement(Box, {
    maxW: {
      md: "650px"
    }
  }, React.createElement(Heading, {
    mt: 6,
    mb: 4,
    as: "h1",
    size: "xl"
  }, title), React.createElement(BreadCrumbGroup, {
    pathArr: ["note", title]
  }), React.createElement(Text, {
    fontSize: "xs",
    mb: 0,
    varinat: "sec"
  }, "Date Created: ", node.frontmatter.date_created), React.createElement(Text, {
    fontSize: "xs",
    varinat: "sec"
  }, "Last Update: ", node.frontmatter.last_modified), React.createElement(Flex, {
    flexWrap: "wrap",
    py: 6
  }, node.frontmatter.categories && node.frontmatter.categories.map((cat, i) => React.createElement(Tag, {
    mr: 1,
    mb: 1,
    variant: "sec",
    key: generate()
  }, " #", cat))), React.createElement(Box, {
    display: {
      base: "block",
      xl: "none"
    }
  }, React.createElement(MdxTOC, {
    tableOfContents: tableOfContents,
    pagePath: path
  })), React.createElement(MDXLayout, null, React.createElement(Box, {
    py: 10
  }, children)))), React.createElement(Box, {
    as: GridItem,
    display: {
      base: "none",
      xl: "block"
    },
    colSpan: {
      base: 0,
      lg: 3
    }
  }, React.createElement(MdxTOC, {
    tableOfContents: tableOfContents,
    pagePath: path
  })))), React.createElement(Container, {
    maxW: "container.xl",
    my: 10
  }, React.createElement(Box, {
    my: 10
  }, React.createElement(Heading, {
    as: "h3",
    size: "xl"
  }, " More ", node.frontmatter.type === "note" ? "Notes" : "/Articles"), node.frontmatter.type === "note" ? React.createElement(LinkOne, {
    as: GatsbyLink,
    to: "/notes"
  }, " All Notes") : React.createElement(LinkOne, {
    as: GatsbyLink,
    to: "/articles"
  }, " All Articles")), React.createElement(HalfByHalfSection, {
    right: previous !== null ? React.createElement(PrevArticle) : "",
    left: next !== null ? React.createElement(NextArticle) : ""
  }))));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(NoteTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const query = graphql`
  query {
    recentNotes: allMdx(
      filter: { frontmatter: { type: { eq: "note" }, isdraft: { eq: false } } }
      sort: { fields: frontmatter___date_created, order: DESC }
    ) {
      edges {
        node {
          parent {
            id
            ... on File {
              id
              name
              dir
              relativeDirectory
              relativePath
            }
          }
          excerpt
          id
          fields {
            path
          }
          frontmatter {
            title
            categories
            description
            tags
            date_created
            last_modified
            type
          }
        }
      }
    }
  }
`;
